<template>
  <div>
    <!--Validation Systems Icons-->
    <div slot="default">
      <v-col class="pa-0 ma-0 text-center">
        <v-tooltip bottom color="fully transparent" max-width="35vw">
          <template v-slot:activator="{ on }">
            <v-btn icon @click="openDialog()" v-on="on" width="28px" height="28px">
              <v-icon :color="getStatusOption.color">{{ getStatusOption.logo }}</v-icon>
            </v-btn>
          </template>
          <span class="custom-tooltip">
            <strong style="color: #f4846e">Validity Status:</strong> {{ titleString(validity.status) }}<br /><br />
            <strong style="color: #f4846e">Comment:</strong> {{ validity.comment ? validity.comment : "No Comment"
            }}<br /><br />
            <strong style="color: #f4846e">Done By:</strong>
            {{
              (isAccount && validity.doneBy
                ? validity.doneBy.fullName ?? validity.doneBy.name
                : validity.doneByUserFullName) || "-"
            }}<br />
            <em style="color: #f4846e">(Validity allows you to check if the order was created correctly.)</em>
          </span>
        </v-tooltip>
        <div v-if="showTextCaption" class="text-caption">Validity Status</div>
      </v-col>
    </div>
    <!--Validation Systems Dialog-->
    <div slot="dialog">
      <v-dialog
        v-model="dialogVisible"
        :loading="isLoading"
        max-width="35%"
        style="overflow: hidden"
        @click:outside="closeDialog">
        <v-card>
          <v-toolbar class="title" color="titlebg" dark dense fixed>
            <v-toolbar-title>Update Validation</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="closeDialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <div class="non-scrollable-dialog">
            <v-row>
              <v-col class="ma-auto mt-5" cols="11">
                <h4 class="mb-2">Validity Status</h4>
                <v-select
                  v-model="validityCopy.status"
                  :item-text="(item) => item.text"
                  :items="statusOptions"
                  dense
                  required
                  solo>
                  <template v-slot:prepend-inner>
                    <v-icon :color="getSelectedStatusOption.color">{{ getSelectedStatusOption.logo }}</v-icon>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-icon :color="item.color" class="pr-1">{{ item.logo }}</v-icon>
                    <span>{{ item.text }}</span>
                  </template>
                </v-select>
              </v-col>
              <v-col class="ma-auto" cols="11">
                <h4 class="mb-2">Comment</h4>
                <v-textarea v-model="validityCopy.comment" class="text-right" required solo></v-textarea>
              </v-col>
            </v-row>
          </div>
          <v-card-actions class="justify-end pb-4 pr-6 px-4">
            <v-btn class="align-self-end" color="primary" @click="closeDialog">CLOSE</v-btn>
            <v-btn class="align-self-end" color="accent" @click="updateValidation">UPDATE</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import Trainplanet from "@/util/trainplanet.api";
import { mapGetters } from "vuex";
import { titleString } from "@/util/helpers";
import { ROLES } from "@/store/modules/roles";

export default {
  props: {
    orderId: String,
    validity: Object,
    showTextCaption: {
      type: Boolean,
      default: true,
    },
    isAccount: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      dialogVisible: false,
      statusOptions: [
        {
          value: "correct",
          text: "Correct",
          logo: "mdi-checkbox-marked-circle-outline",
          color: "green",
        },
        {
          value: "unchecked",
          text: "Unchecked",
          logo: "mdi-equal",
          color: "mango-orange",
        },
        {
          value: "incorrect",
          text: "Incorrect",
          logo: "mdi-close-thick",
          color: "mango-red",
        },
      ],
      validityCopy: {
        comment: "-",
        doneBy: null,
        status: "unchecked",
      },
    };
  },

  computed: {
    ...mapGetters({
      userRoles: "auth/userRoles",
    }),
    getStatusOption() {
      return this.statusOptions.find((option) => option.value === this.validity.status);
    },
    getSelectedStatusOption() {
      return this.statusOptions.find((option) => option.value === this.validityCopy.status);
    },
    userCanValidate() {
      const rolesThatCanValidate = [ROLES.ORDER_VALIDATE, ROLES.ADMIN_BACK_OFFICE, ROLES.ADMIN];

      for (let role of rolesThatCanValidate) {
        if (this.userRoles.includes(role)) {
          return true;
        }
      }

      return false;
    },
  },

  created() {
    this.resetValidityCopy();
  },

  watch: {
    validity: {
      handler() {
        this.resetValidityCopy();
      },
      deep: true,
    },
  },

  methods: {
    titleString,
    openDialog() {
      if (this.userCanValidate) {
        this.dialogVisible = true;
      }
    },
    closeDialog() {
      this.dialogVisible = false;
      this.resetValidityCopy();
    },
    resetValidityCopy() {
      this.validityCopy = { ...this.validity };
    },
    async updateValidation() {
      try {
        this.isLoading = true; // FIXME isLoading is not working on the dialog

        this.validityCopy = await Trainplanet.validateOrder(this.orderId, this.validityCopy);
        this.$emit("update:validity", this.validityCopy);

        await this.$store.dispatch("success", "Order validated successfully.");
        this.dialogVisible = false;
      } catch (error) {
        await this.$store.dispatch("error", error.response.data.message || error.message || error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
<style scoped>
.non-scrollable-dialog {
  max-height: none !important;
  overflow: hidden !important;
}

.custom-tooltip {
  display: inline-block;
  padding: 10px;
  border-radius: 10px;
  border: 2px solid #f4846e;
  background-color: white;
  color: #444;
}
</style>
