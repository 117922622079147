var render = function render(){
  var _vm$validity$doneBy$f;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    attrs: {
      "slot": "default"
    },
    slot: "default"
  }, [_c('v-col', {
    staticClass: "pa-0 ma-0 text-center"
  }, [_c('v-tooltip', {
    attrs: {
      "bottom": "",
      "color": "fully transparent",
      "max-width": "35vw"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('v-btn', _vm._g({
          attrs: {
            "icon": "",
            "width": "28px",
            "height": "28px"
          },
          on: {
            "click": function ($event) {
              return _vm.openDialog();
            }
          }
        }, on), [_c('v-icon', {
          attrs: {
            "color": _vm.getStatusOption.color
          }
        }, [_vm._v(_vm._s(_vm.getStatusOption.logo))])], 1)];
      }
    }])
  }, [_c('span', {
    staticClass: "custom-tooltip"
  }, [_c('strong', {
    staticStyle: {
      "color": "#f4846e"
    }
  }, [_vm._v("Validity Status:")]), _vm._v(" " + _vm._s(_vm.titleString(_vm.validity.status))), _c('br'), _c('br'), _c('strong', {
    staticStyle: {
      "color": "#f4846e"
    }
  }, [_vm._v("Comment:")]), _vm._v(" " + _vm._s(_vm.validity.comment ? _vm.validity.comment : "No Comment")), _c('br'), _c('br'), _c('strong', {
    staticStyle: {
      "color": "#f4846e"
    }
  }, [_vm._v("Done By:")]), _vm._v(" " + _vm._s((_vm.isAccount && _vm.validity.doneBy ? (_vm$validity$doneBy$f = _vm.validity.doneBy.fullName) !== null && _vm$validity$doneBy$f !== void 0 ? _vm$validity$doneBy$f : _vm.validity.doneBy.name : _vm.validity.doneByUserFullName) || "-")), _c('br'), _c('em', {
    staticStyle: {
      "color": "#f4846e"
    }
  }, [_vm._v("(Validity allows you to check if the order was created correctly.)")])])]), _vm.showTextCaption ? _c('div', {
    staticClass: "text-caption"
  }, [_vm._v("Validity Status")]) : _vm._e()], 1)], 1), _c('div', {
    attrs: {
      "slot": "dialog"
    },
    slot: "dialog"
  }, [_c('v-dialog', {
    staticStyle: {
      "overflow": "hidden"
    },
    attrs: {
      "loading": _vm.isLoading,
      "max-width": "35%"
    },
    on: {
      "click:outside": _vm.closeDialog
    },
    model: {
      value: _vm.dialogVisible,
      callback: function ($$v) {
        _vm.dialogVisible = $$v;
      },
      expression: "dialogVisible"
    }
  }, [_c('v-card', [_c('v-toolbar', {
    staticClass: "title",
    attrs: {
      "color": "titlebg",
      "dark": "",
      "dense": "",
      "fixed": ""
    }
  }, [_c('v-toolbar-title', [_vm._v("Update Validation")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('div', {
    staticClass: "non-scrollable-dialog"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "ma-auto mt-5",
    attrs: {
      "cols": "11"
    }
  }, [_c('h4', {
    staticClass: "mb-2"
  }, [_vm._v("Validity Status")]), _c('v-select', {
    attrs: {
      "item-text": function (item) {
        return item.text;
      },
      "items": _vm.statusOptions,
      "dense": "",
      "required": "",
      "solo": ""
    },
    scopedSlots: _vm._u([{
      key: "prepend-inner",
      fn: function () {
        return [_c('v-icon', {
          attrs: {
            "color": _vm.getSelectedStatusOption.color
          }
        }, [_vm._v(_vm._s(_vm.getSelectedStatusOption.logo))])];
      },
      proxy: true
    }, {
      key: "item",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('v-icon', {
          staticClass: "pr-1",
          attrs: {
            "color": item.color
          }
        }, [_vm._v(_vm._s(item.logo))]), _c('span', [_vm._v(_vm._s(item.text))])];
      }
    }]),
    model: {
      value: _vm.validityCopy.status,
      callback: function ($$v) {
        _vm.$set(_vm.validityCopy, "status", $$v);
      },
      expression: "validityCopy.status"
    }
  })], 1), _c('v-col', {
    staticClass: "ma-auto",
    attrs: {
      "cols": "11"
    }
  }, [_c('h4', {
    staticClass: "mb-2"
  }, [_vm._v("Comment")]), _c('v-textarea', {
    staticClass: "text-right",
    attrs: {
      "required": "",
      "solo": ""
    },
    model: {
      value: _vm.validityCopy.comment,
      callback: function ($$v) {
        _vm.$set(_vm.validityCopy, "comment", $$v);
      },
      expression: "validityCopy.comment"
    }
  })], 1)], 1)], 1), _c('v-card-actions', {
    staticClass: "justify-end pb-4 pr-6 px-4"
  }, [_c('v-btn', {
    staticClass: "align-self-end",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_vm._v("CLOSE")]), _c('v-btn', {
    staticClass: "align-self-end",
    attrs: {
      "color": "accent"
    },
    on: {
      "click": _vm.updateValidation
    }
  }, [_vm._v("UPDATE")])], 1)], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }