var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-toolbar', {
    staticClass: "primary--text elevation-1 rounded mb-2",
    attrs: {
      "dense": "",
      "color": _vm.color ? _vm.color : 'grey lighten-4'
    }
  }, [_c('v-toolbar-title', {
    staticClass: "font-weight-bold",
    class: _vm.color ? 'white--text' : 'black--text'
  }, [_c('span', [_vm._v(_vm._s(_vm.title))]), _vm._v(" "), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v(_vm._s(_vm.titleRed))])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }