var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-0"
  }, [_c('v-row', _vm._l(_vm.items.filter(function (value) {
    return !value.hidden;
  }), function (item, index) {
    return _c('v-col', {
      key: item.name,
      staticClass: "pb-0",
      attrs: {
        "lg": _vm.getNumberOfCols(index),
        "cols": "12"
      }
    }, [_c('div', {
      staticClass: "pb-0"
    }, [item.name === 'Seller2' ? _c('div', {
      on: {
        "click": _vm.openSellerDialog
      }
    }, [_c('fieldset', {
      staticClass: "custom-wrapper"
    }, [_c('legend', {
      staticStyle: {
        "font-size": "12px",
        "margin-left": "9px",
        "color": "#979799ff"
      }
    }, [_vm._v("Select Seller ")]), _c('v-row', [_vm.shouldShowAllSeller ? _c('v-col', {
      staticClass: "ml-2",
      staticStyle: {
        "margin-top": "6px",
        "margin-bottom": "10px"
      },
      attrs: {
        "position": "relative"
      }
    }, [_c('div', [_vm._v("All")])]) : _vm._e(), _c('v-col', {
      staticClass: "ml-3 pr-0"
    }, [_vm._l(_vm.visibleChips, function (item, index) {
      var _item$fullName;
      return _vm.shouldShowChip(index) ? _c('v-chip', {
        key: index,
        staticClass: "custom-chip mb-2",
        staticStyle: {
          "background-color": "#E0E0E0 !important",
          "border": "transparent !important"
        },
        attrs: {
          "outlined": ""
        }
      }, [_c('v-list-item-avatar', {
        staticStyle: {
          "margin-right": "2px",
          "margin-top": "5px"
        },
        attrs: {
          "size": "20"
        }
      }, [_c('v-icon', {
        attrs: {
          "disabled": false,
          "color": "black"
        }
      }, [_vm._v(_vm._s(item.icon))])], 1), _c('v-list-item-title', {
        style: {
          color: `black`
        }
      }, [_c('h4', {
        staticStyle: {
          "font-size": "12px"
        }
      }, [_vm._v(_vm._s((_item$fullName = item.fullName) !== null && _item$fullName !== void 0 ? _item$fullName : item.name))])])], 1) : _vm._e();
    }), _vm.shouldShowExtraChip ? _c('div', {
      staticClass: "ml-lg-n1",
      staticStyle: {
        "font-size": "12px",
        "color": "#9f9f9fff",
        "display": "inline-block",
        "margin-top": "8px",
        "position": "absolute"
      }
    }, [_vm._v(" (+" + _vm._s(_vm.extraChipCount) + ") ")]) : _vm._e()], 2)], 1), _c('div', {
      staticClass: "mdi-menu-down-icon"
    }, [_c('v-icon', {
      attrs: {
        "disabled": false,
        "color": "gray"
      }
    }, [_vm._v("mdi-menu-down")])], 1)], 1)]) : item.name !== 'Seller' ? _c('v-select', {
      staticClass: "pb-0",
      attrs: {
        "items": item.items,
        "label": item.name,
        "dense": "",
        "hide-details": "",
        "item-text": "label",
        "item-value": "value",
        "outlined": ""
      },
      model: {
        value: item.selectedValue,
        callback: function ($$v) {
          _vm.$set(item, "selectedValue", $$v);
        },
        expression: "item.selectedValue"
      }
    }) : _vm._e()], 1)]);
  }), 1), _c('v-dialog', {
    attrs: {
      "max-width": "75%"
    },
    model: {
      value: _vm.sellerDialog,
      callback: function ($$v) {
        _vm.sellerDialog = $$v;
      },
      expression: "sellerDialog"
    }
  }, [_c('v-card', [_c('v-toolbar', {
    staticClass: "title",
    attrs: {
      "color": "titlebg",
      "dark": "",
      "dense": "",
      "fixed": ""
    }
  }, [_c('v-toolbar-title', [_vm._v("Seller")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('div', {
    staticClass: "pa-7 pb-0 rounded-lg"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12"
    }
  }, _vm._l(_vm.items, function (item) {
    return _c('div', {
      key: item.name
    }, [item.name === 'Seller' ? _c('v-select', {
      staticClass: "pb-0",
      attrs: {
        "items": item.items.filter(function (subitem) {
          return !subitem.hidden;
        }),
        "dense": "",
        "hide-details": "",
        "item-text": "label",
        "item-value": "value",
        "label": "Systems Seller",
        "outlined": ""
      },
      on: {
        "change": function ($event) {
          return _vm.handleSellerChange(item);
        }
      },
      model: {
        value: item.selectedValue,
        callback: function ($$v) {
          _vm.$set(item, "selectedValue", $$v);
        },
        expression: "item.selectedValue"
      }
    }) : _vm._e()], 1);
  }), 0), _c('v-col', {
    attrs: {
      "cols": "11/2"
    }
  }, [_c('div', [_c('v-tabs', {
    staticClass: "mb-1"
  }, [_c('v-tab', {
    staticClass: "",
    on: {
      "click": function ($event) {
        _vm.activeTab = 'users';
      }
    }
  }, [_c('h4', [_vm._v("Users")])]), _c('v-tab', {
    staticClass: "",
    on: {
      "click": function ($event) {
        _vm.activeTab = 'groups';
      }
    }
  }, [_c('h4', [_vm._v("Groups")])])], 1), _c('v-tabs-items', {
    model: {
      value: _vm.activeTab,
      callback: function ($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, [_c('v-tab-item', {
    attrs: {
      "value": "users"
    }
  }, [_c('div', {}, [_c('v-row', [_c('v-col', [_c('v-text-field', {
    ref: "search",
    staticClass: "mt-3 mb-3 rounded-lg",
    attrs: {
      "loading": _vm.searching,
      "dense": "",
      "full-width": "",
      "hide-details": "",
      "label": "Search",
      "outlined": "",
      "prepend-inner-icon": "mdi-account-search",
      "single-line": ""
    },
    model: {
      value: _vm.userSearchQuery,
      callback: function ($$v) {
        _vm.userSearchQuery = $$v;
      },
      expression: "userSearchQuery"
    }
  }), _c('h4', {
    staticClass: "mb-0"
  }, [_vm._v("Search Result")]), _c('v-list', {
    staticStyle: {
      "height": "300px",
      "overflow-y": "auto"
    },
    attrs: {
      "dense": ""
    }
  }, [_vm.filteredUsers.length > 0 ? _c('div', [_vm._l(_vm.filteredUsers, function (item) {
    var _item$fullName2;
    return [_c('v-list-item', {
      key: item.id,
      staticClass: "rounded-lg mb-2",
      attrs: {
        "disabled": _vm.loading,
        "dense": ""
      }
    }, [_c('v-list-item-avatar', {
      attrs: {
        "size": "20"
      }
    }, [_c('v-icon', {
      attrs: {
        "disabled": false,
        "color": "mango-red",
        "dense": ""
      },
      domProps: {
        "textContent": _vm._s(item.icon)
      }
    })], 1), _c('v-list-item-title', {
      domProps: {
        "textContent": _vm._s((_item$fullName2 = item.fullName) !== null && _item$fullName2 !== void 0 ? _item$fullName2 : item.name)
      }
    }), _c('v-list-item-subtitle', {
      domProps: {
        "textContent": _vm._s(item.email)
      }
    }), _c('v-btn', {
      attrs: {
        "icon": ""
      },
      on: {
        "click": function ($event) {
          return _vm.createUserSelection(item);
        }
      }
    }, [_c('v-icon', [_vm._v("mdi-plus")])], 1)], 1)];
  })], 2) : _vm._e()])], 1)], 1)], 1)]), _c('v-tab-item', {
    attrs: {
      "value": "groups"
    }
  }, [_c('div', [_c('v-row', [_c('v-col', [_c('v-text-field', {
    ref: "search",
    staticClass: "mt-3 mb-3 rounded-lg",
    attrs: {
      "loading": _vm.searching,
      "dense": "",
      "full-width": "",
      "hide-details": "",
      "label": "Search",
      "outlined": "",
      "prepend-inner-icon": "mdi-account-search",
      "single-line": ""
    },
    model: {
      value: _vm.groupSearchQuery,
      callback: function ($$v) {
        _vm.groupSearchQuery = $$v;
      },
      expression: "groupSearchQuery"
    }
  }), _c('h4', {
    staticClass: "mb-0"
  }, [_vm._v("Search Result")]), _c('v-list', {
    staticStyle: {
      "height": "300px",
      "overflow-y": "auto"
    },
    attrs: {
      "dense": ""
    }
  }, [_vm.filteredGroups.length > 0 ? _c('div', [_vm._l(_vm.filteredGroups, function (group) {
    return [_c('v-list-item', {
      key: group.name,
      staticClass: "rounded-lg mb-2",
      attrs: {
        "disabled": _vm.loading,
        "dense": ""
      }
    }, [_c('v-list-item-avatar', {
      attrs: {
        "size": "20"
      }
    }, [_c('v-icon', {
      attrs: {
        "disabled": false,
        "color": "mango-red",
        "dense": ""
      },
      domProps: {
        "textContent": _vm._s(group.icon)
      }
    })], 1), _c('v-list-item-title', {
      domProps: {
        "textContent": _vm._s(group.name)
      }
    }), _c('v-btn', {
      attrs: {
        "icon": ""
      },
      on: {
        "click": function ($event) {
          return _vm.createGroupSelection(group);
        }
      }
    }, [_c('v-icon', [_vm._v("mdi-plus")])], 1)], 1)];
  })], 2) : _vm._e()])], 1)], 1)], 1)])], 1)], 1)]), _c('v-col', {
    staticClass: "pa-0 ma-0 mt-10 mb-13",
    staticStyle: {
      "text-align": "center"
    },
    attrs: {
      "cols": "1"
    }
  }, [_c('v-divider', {
    staticClass: "mt-5",
    staticStyle: {
      "border": "1px solid #f4846e !important"
    },
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "11/2"
    }
  }, [_c('div', [_c('h4', {
    staticClass: "mt-4",
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v("Selected Users & Groups (" + _vm._s(_vm.selectedUsersAndGroups.length) + ")")]), _c('v-divider', {
    staticClass: "mt-1 mr-15 ml-15 mb-1",
    staticStyle: {
      "border": "1px solid #f4846e !important"
    }
  }), _c('v-list', {
    staticStyle: {
      "height": "300px",
      "overflow-y": "auto"
    }
  }, _vm._l(_vm.selectedUsersAndGroups, function (selection, i) {
    var _selection$fullName;
    return _c('v-list-item', {
      key: selection.id,
      staticClass: "list-item shrink rounded-lg mb-2",
      staticStyle: {
        "cursor": "pointer"
      },
      style: {
        background: `#f4846e`,
        height: `42px`
      }
    }, [_c('v-list-item-avatar', {
      attrs: {
        "size": "20"
      }
    }, [_c('v-icon', {
      attrs: {
        "disabled": false,
        "color": "white"
      }
    }, [_vm._v(_vm._s(selection.icon) + " ")])], 1), _c('v-list-item-title', {
      style: {
        color: `white`
      }
    }, [_vm._v(_vm._s((_selection$fullName = selection.fullName) !== null && _selection$fullName !== void 0 ? _selection$fullName : selection.name) + " ")]), selection.email ? _c('v-list-item-subtitle', {
      domProps: {
        "textContent": _vm._s(selection.email)
      }
    }) : _vm._e(), _c('v-btn', {
      attrs: {
        "icon": ""
      },
      on: {
        "click": function ($event) {
          return _vm.removeUserOrGroupSelection(i);
        }
      }
    }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1);
  }), 1)], 1)])], 1)], 1), _c('v-card-actions', {
    staticClass: "justify-lg-space-between pb-9 pr-7 pl-7 px-4"
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.resetAll
    }
  }, [_vm._v("reset")]), _c('v-btn', {
    attrs: {
      "loading": _vm.submitLoader,
      "color": "accent",
      "dark": ""
    },
    on: {
      "click": _vm.applyFilters
    }
  }, [_vm._v(" APPLY ")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }