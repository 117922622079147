<template>
  <div>
    <v-toolbar dense :color="color ? color : 'grey lighten-4'" class="primary--text elevation-1 rounded mb-2">
      <v-toolbar-title :class="color ? 'white--text' : 'black--text'" class="font-weight-bold">
        <span>{{ title }}</span> <span class="primary--text">{{ titleRed }}</span>
      </v-toolbar-title>
    </v-toolbar>
  </div>
</template>
<script>
export default {
  props: {
    color: String,
    title: String,
    titleRed: String,
  },
};
</script>
<style lang="scss" scoped></style>
